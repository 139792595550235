import React from "react";
import BetterTabs from './Project/BetterTabs';
import ImgBase from "./Project/ImgBase";
import TravelLocations from "./Project/TravelLocations";
import NBAStats from './Project/NBAStats';
import TMDBMovies from './Project/TMDBMovies';
import QuickBlackjack from './Project/QuickBlackjack';

function Projects() {
  return (
    <section id="projects" className="projects">
      <div className="projects--content">
        <h1>&laquo; Projects &raquo;</h1>
        <hr className="gradient-line" />
        <BetterTabs />
        <ImgBase />
        <TravelLocations />
        <NBAStats />
        <TMDBMovies />
        <QuickBlackjack />
      </div>
    </section>
  );
}

export default Projects;
