import React from "react";
import './css/App.css';
import MainApp from "./containers/MainApp";


export default function App() {

    return (
      <div className="App">
        <MainApp />
      </div>
    );
}
