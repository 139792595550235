import React from "react";

function BetterTabs() {
  return (
    <div className="project" id="BetterTabs">
      <div className="project-flex">
        <div className="project-text">
          <h3>BetterTabs</h3>
          <p>
            This browser extension allows you to view & manage your tabs more easily.
          </p>
          <p className="project-details">
            <strong>Front-End: </strong> React, JavaScript, SASS/SCSS, Material UI
            <br />
            <strong>Other: </strong> Browser extension
          </p>
          <p className="project-links">
          <a
              href="https://chrome.google.com/webstore/detail/ejgipnaddpkjeenblbbahbnkjnjcpljb"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on the Google Chrome web store
            </a>            <br />
            <a
              href="https://github.com/adamtjay/BetterTabs"
              target="_blank"
              rel="noopener noreferrer"
            >
              &laquo; View the code on GitHub &raquo;
            </a>
          </p>
        </div>
        <div className="project-img">
          <a
            href={require("../../../img/projects/BetterTabs.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../img/projects/BetterTabs.png")}
              alt="BetterTabs Browser Extension"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BetterTabs;
