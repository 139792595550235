import React from "react";

function Home() {
  return (
    <div id="home" className="home">
      <div className="home--content">
        <div className="home--top-section">
          <h1 className="home--main-text">
            Adam
            <br />
            Julier
          </h1>
          <h2 className="home--subtitle">Full-Stack Developer</h2>
          <h2 className="home--subtitle">NYC</h2>
        </div>
        <hr />
        <div className="main-menu">
          <a href="#about" className="btn main-menu--btn btn--animated">
            About
          </a>
          <a href="#skills" className="btn main-menu--btn btn--animated">
            Skills
          </a>
          <a href="#projects" className="btn main-menu--btn btn--animated">
            Projects
          </a>
          <a
            href="https://drive.google.com/file/d/1WeH7jEwgPH84JNoVjU2FoZv54YLKWiLF/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="btn main-menu--btn btn--animated"
          >
            Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
