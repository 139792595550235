import React, { Component } from "react";
import Navigation from "../components/Navigation/Navigation";
import Header from "../components/Header/Header";
import Home from "../components/Home/Home";
import About from "../components/About/About";
import Skills from "../components/Skills/Skills";
import Projects from "../components/Projects/Projects";
import Footer from "../components/Footer/Footer";

class MainApp extends Component {

  render() {
    return (
      <React.Fragment>
        <Navigation />
        <Header>
          <Home />
        </Header>
        <About />
        <Skills />
        <Projects />
        <Footer />
      </React.Fragment>
    );
  }
}

export default MainApp;
