import React from "react";

function NBAStats() {
  return (
    <div className="project" id="NBA-Stats">
      <div className="project-flex">
        <div className="project-text">
          <h3>NBA Stats + Team Maker</h3>
          <p>
            View stats, create and compare fantasy teams of your favorite NBA players
            using the NBA API.
          </p>
          <p className="project-details">
            <strong>Front-End: </strong> EJS, JavaScript
            <br />
            <strong>Back-End: </strong> Node.js, JavaScript
            <br />
            <strong>Database: </strong> Postgres <em>(SQL)</em>
            <br />
            <strong>Other: </strong> NBA REST API
          </p>
          <p className="project-links">
            <a
              href="https://drive.google.com/file/d/16bNo1oUU-1Mp89W0aRRffOci9ebGrcdJ/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Video Demo
            </a>
            <br />
            <a
              href="https://github.com/adamtjay/nba-team-maker"
              target="_blank"
              rel="noopener noreferrer"
            >
              &laquo; View the code on GitHub &raquo;
            </a>
          </p>
        </div>
        <div className="project-img">
          <a
            href={require("../../../img/projects/nba-team-maker.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../img/projects/nba-team-maker.png")}
              alt="NBA Stats + Team Maker"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default NBAStats;
