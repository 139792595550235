import React, { Component } from "react";

class Header extends Component {
  state = {
    currentImg: "header--img-1"
  };

  rotateImgs() {
    let current = parseInt(
      this.state.currentImg.charAt(this.state.currentImg.length - 1)
    );
    current === 7
      ? this.setState({
          currentImg: "header--img-1"
        })
      : this.setState({
          currentImg: "header--img-" + (current + 1)
        });
  }

  componentDidMount() {
    setInterval(() => {
      this.rotateImgs();
    }, 15000);
  }

  render() {
    return (
      <header className={"header--main " + this.state.currentImg}>
        {this.props.children}
      </header>
    );
  }
}

export default Header;
