import React from "react";

function TravelLocations() {
  return (
    <div className="project">
      <div className="project-flex">
        <div className="project-text">
          <h3>Travel Locations</h3>
          <p>
            Allows the user to view a selected list of
            potential travel locations, rendering each area on the map using the
            Google Maps API. Displays nearby places of interest using the Google
            Places API and allows the user to save notes.
          </p>
          <p className="project-details">
            <strong>Front-End: </strong> React, JavaScript
            <br />
            <strong>Back-End: </strong> Node.js, JavaScript
            <br />
            <strong>Database: </strong> Postgres <em>(SQL)</em>
            <br />
            <strong>Other: </strong> Google Maps & Places REST APIs
          </p>
          <p className="project-links">
              <a href="https://travel-locations.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                 Demo
              </a><br/>
            <a href="https://github.com/adamtjay/travel-app">
              &laquo; View the code on GitHub &raquo;
            </a>
          </p>
        </div>
        <div className="project-img">
          <a href={require("../../../img/projects/travel-locations.png")} target="_blank" rel="noopener noreferrer">
            <img
              src={require("../../../img/projects/travel-locations.png")}
              alt="Travel Locations App"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TravelLocations;
