import React from "react";
import CodeTwoToneIcon from "@material-ui/icons/CodeTwoTone";
import StorageIcon from "@material-ui/icons/Storage";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

function Skills() {
  return (
    <section id="skills" className="skills">
      <div className="skills--content">
        <h1>&laquo; Skills &raquo;</h1>
        <hr className="gradient-line-light"></hr>
        <div className="skills--content-flex">
          <div className="skill">
            <h3>
              Front-End
              <br />
              Development
            </h3>
            <CodeTwoToneIcon fontSize="large" />
            <div className="skill-columns">
              <p>
                JavaScript (ES6)
                <br />
                React
                <br />
                Redux
                <br />
                TypeScript
                <br />
                Vue.js
                <br />
                JSX
                <br />
                jQuery
                <br />
                NPM, Yarn
              </p>
              <p>
                HTML5
                <br />
                CSS3
                <br />
                SASS / SCSS
                <br />
                Bootstrap
                <br />
                Flexbox & Grid
                <br />
                Material UI
                <br />
                Webpack, Babel
                <br />
                Gulp, Grunt
              </p>
            </div>
          </div>
          <div className="skill">
            <h3>
              Back-End
              <br />
              Development
            </h3>
            <StorageIcon fontSize="large" />
            <p>
              Node.js & Express
              <br />
              Django
              <br />
              Python
              <br />
              WordPress
              <br />
              PHP
              <br />
              Ruby on Rails
              <br />
              MVC Frameworks
            </p>
          </div>
          <div className="skill">
            <h3>Web APIs & Databases</h3>
            <DataUsageIcon fontSize="large" />
            <p>
              REST & SOAP APIs
              <br />
              GraphQL
              <br />
              JSON & JWT
              <br />
              SQL Relational Databases
              <br />
              Postgres
              <br />
              MySQL
              <br />
              noSQL Databases
              <br />
              MongoDB
              <br />
              Redis
            </p>
          </div>
          <div className="skill">
            <h3>
              Mobile
              <br />
              Applications
            </h3>
            <PhoneAndroidIcon fontSize="large" />
            <p>
              React Native
              <br />
              Swift & SwiftUI
              <br />
              Xcode
            </p>
          </div>
        </div>
        <div className="skills--content-bottom">
          <div className="skills--content-bottom-flex">
            <h4>Responsive & Single Page Web Applications</h4>
            <h4>
              Version Control
              <br />
              (Git, GitHub)
            </h4>
            <h4>Agile Software Development</h4>
            <h4>UI/UX Design</h4>
            <h4>Search Engine Optimization (SEO)</h4>
          </div>
          <div className="skills--content-bottom-flex">
            <p>
              Additional details can be found on my{" "}
              <a
                href="https://drive.google.com/file/d/1WeH7jEwgPH84JNoVjU2FoZv54YLKWiLF/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resume/CV
              </a>{" "}
              and{" "}
              <a
                href="https://linkedin.com/in/adamtj"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
