import React from "react";

function QuickBlackjack() {
  return (
    <div className="project" id="Quick-Blackjack">
      <div className="project-flex">
        <div className="project-text">
          <h3>Quick Blackjack</h3>
          <p>
          A pick-up-and-play browser version of Blackjack (1P vs. CPU).
          </p>
          <p className="project-details">
            <strong>Front-End: </strong> JavaScript, jQuery
          </p>
          <p className="project-links">
            <a
              href="https://adamtjay.github.io/quick-blackjack-game/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo
            </a>
            <br />
            <a
              href="https://github.com/adamtjay/quick-blackjack-game"
              target="_blank"
              rel="noopener noreferrer"
            >
              &laquo; View the code on GitHub &raquo;
            </a>
          </p>
        </div>
        <div className="project-img">
          <a
            href={require("../../../img/projects/quick-blackjack.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../img/projects/quick-blackjack.png")}
              alt="Quick Blackjack Game"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default QuickBlackjack;
