import React from "react";

function About() {
  return (
    <section id="about" className="about">
      <div className="about--content">
        <h1>&laquo;    About Me    &raquo;</h1>
        <hr className="gradient-line"></hr>
        <div className="about--content-flex">
          <div className="about--content-img">
            <img src={require("../../img/Adam.png")} alt="Adam Julier" />
          </div>
          <p className="about--content-text">
            My name is Adam Julier, and I'm a software engineer based in New York City.
            <br/><br/>
            I am experienced in creating & delivering responsive full-stack web applications using a variety of technologies and tools.
            Whether it's working on a front-end client & UI, on the back-end using a server-side framework, or even a native mobile application, 
            I find all aspects of modern development to be extremely fun and interesting.
            <br/><br/>
            In my free time when I'm not working on a side project or learning a new language / framework,
            you may find me enjoying views of the city, listening to music, playing guitar, watching sports, or at the gym.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
