import React from "react";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer--content">
        <div className="footer--content-center">
          <a
            href="https://github.com/adamtjay"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHubIcon fontSize="large" />
            <span className="icon-text">GitHub</span>
          </a>
          <a
            href="https://linkedin.com/in/adamtj"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon fontSize="large" />
            <span className="icon-text">LinkedIn</span>
          </a>
          <a
            href="mailto:contact@adamtj.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MailOutlineIcon fontSize="large" />
            <span className="icon-text">Email</span>
          </a>
        </div>
        <div className="footer--content--bottom">
          &copy; 2020 Adam Julier
        </div>
      </div>
    </footer>
  );
}

export default Footer;
