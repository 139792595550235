import React from "react";

function TMDBMovies() {
  return (
    <div className="project">
      <div className="project-flex">
        <div className="project-text">
          <h3>TMDB Movie Viewer</h3>
          <p>
            View movie information using the TMDB API, and create a list of favorites.
          </p>
          <p className="project-details">
            <strong>Front-End: </strong> React, JavaScript
            <br />
            <strong>Other: </strong> TMDB REST API
          </p>
          <p className="project-links">
            <a
              href="https://react-movies-app.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo
            </a>
            <br />
            <a
              href="https://github.com/adamtjay/react-movies-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              &laquo; View the code on GitHub &raquo;
            </a>
          </p>
        </div>
        <div className="project-img">
          <a
            href={require("../../../img/projects/TMDB-movies-app.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../img/projects/TMDB-movies-app.png")}
              alt="TMDB Movie Viewer"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default TMDBMovies;
