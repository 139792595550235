import React from "react";

function ImgBase() {
  return (
    <div className="project" id="imgBase">
      <div className="project-flex">
        <div className="project-text">
          <h3>imgBase</h3>
          <p>
            A mobile iOS/Android application which allows the user to
            add keywords to images in their camera roll, and search through
            their images.
          </p>
          <p className="project-details">
            <strong>Front-End: </strong> React Native, JavaScript
            <br />
            <strong>Back-End: </strong> Django, Python
            <br />
            <strong>Database: </strong> Postgres <em>(SQL)</em>
            <br />
            <strong>Other: </strong> Custom REST API
          </p>
          <p className="project-links">
            <a
              href="https://github.com/adamtjay/imgbase"
              target="_blank"
              rel="noopener noreferrer"
            >
              &laquo; View the React Native mobile client on GitHub &raquo;
            </a>
            <br />
            <a
              href="https://github.com/adamtjay/imgbasepython"
              target="_blank"
              rel="noopener noreferrer"
            >
              &laquo; View the Django server on GitHub &raquo;
            </a>
          </p>
        </div>
        <div className="project-img">
          <a
            href={require("../../../img/projects/imgbase-1.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../img/projects/imgbase-1.png")}
              alt="imgBase-1"
            />
          </a>
          <a
            href={require("../../../img/projects/imgbase-2.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../img/projects/imgbase-2.png")}
              alt="imgBase-2"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ImgBase;
