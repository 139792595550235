import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

class Navigation extends Component {
  render() {
    return (
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand href="#">
          <span className="nav-chevron">&laquo;</span>{" "}
          <span className="nav-text-1">adam</span>
          <span className="nav-text-2">tj</span>{" "}
          <span className="nav-chevron">&raquo;</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#skills">Skills</Nav.Link>
            <Nav.Link href="#projects">Projects</Nav.Link>
            <Nav.Link
              href="https://drive.google.com/file/d/1WeH7jEwgPH84JNoVjU2FoZv54YLKWiLF/view?usp=sharing"
              target="_Blank"
            >
              Resume
            </Nav.Link>
          </Nav>
          <div className="nav-iconbar mr-sm-2">
            <a
              href="https://github.com/adamtjay"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon />
              <span className="icon-text">GitHub</span>
            </a>
            <a
              href="https://linkedin.com/in/adamtj"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedInIcon />
              <span className="icon-text">LinkedIn</span>
            </a>
            <a
              href="mailto:contact@adamtj.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MailOutlineIcon />
              <span className="icon-text">Email</span>
            </a>
          </div>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
